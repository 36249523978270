<template>
    <div>
        <div class="card">
            <img src="../../assets/img/ambulance.png" alt="">
            <p class="name">白金会员卡</p>
            <p class="number">4569 5658 4548 454</p>
            <p class="time">2020.12.10-2021.2.10</p>
            <p class="price">免费</p>
        </div>

        <div class="tq">
            <h4>我的特权</h4>
            <ul>
                <li>1.可使用优惠券；</li>
                <li>2.专业客服服务；</li>
                <li>3.后台配置，个人特权；</li>
            </ul>
            <h4>使用规则</h4>
            <div class="con">
                <p>后台配置，使用规则；后台配置，使用规则；后台配置，使用规则；后台配置，使用规则；后台配置，使用规则；后台配置，使用规则；后台配置，使用规则；后台配置，使用规则；后台配置，使用规则；后台配置，使用规则；后台配置，使用规则；后台配置，使用规则；后台配置，使用规则；后台配置，使用规则；后台配置，使用规则；后台配置，使用规则；后台配置，使用规则；后台配置，使用规则；后台配置，使用规则；后台配置，使用规则；</p>
            </div>
        </div>

        <div class="button">
            <button @click="goinfo('openCard')">立即开通</button>
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        goinfo(names){
            this.$router.push({name: names})
        },
    }
}
</script>

<style lang="less" scoped>
.card {
    width: 100%; height: 190px; background: url('../../assets/img/card.png') no-repeat center center; position: relative; background-size: 100%; margin-top: 10px;
    img { width: 52px; height: 20px; display: block; float: left; position: absolute; top: 20px; left: 32px;} 
    .name { font-style:italic; color: #E2F5FF; font-size: 14px; font-weight: bold; line-height: 20px; position: absolute; left: 100px; top: 20px} 
    .number { text-align: center; font-size: 24px; color: #FFFFFF; line-height: 30px; padding-top: 80px}
    .time { font-size: 12px; color: #FFFFFF; position: absolute; top: 140px; right: 48px;}  
    .price { background: linear-gradient(129deg,#ffe4bc 15%, #c18241); border-radius: 15px 0 0 15px; width: 76px; height: 30px; font-size: 14px; color: #FFFFFF; font-weight: blod; line-height: 30px; text-align: center; right: 16px; top: 30px; position: absolute}
}

.tq { width: 100%;
    h4 { font-size: 18px; color: #444; font-weight: bold; margin: 16px;}
    ul { 
        width: 343px; padding: 15px 16px; border: 1px solid #D8D8D8; margin: 0 auto;
        li { font-size: 14px; color:#444; line-height: 19px; } 
    } 
    .con { font-size: 14px; color:#444; line-height: 19px; width: 343px; margin: 0 auto;} 
} 

.button button { width: 343px; height: 44px; border-radius: 10px; border: none; background: #1DAEFC; display: block; margin: 16px auto; color: #fff;} 
</style>